export const sections = {
  about: {
    name: "about",
    title: "software\u00a0//\u00a0algorithms\u00a0// data\u00a0science\u00a0//\u00a0research",
    text: "We develop novel solutions to hard problems. Hire us to help solve yours."
  },

  expertise: {
    name: "expertise",
    title: "Expertise",
    list: [
      "Machine Learning / AI",
      "Computer Vision",
      "Real-Time Computing",
      "Medical Imaging",
      "Robotics / Automation",
      "Advanced Data Science",
      "Enterprise Software Development",
      "Research Program Development",
      "AI Ethics and Fairness",
      "Optimization",
      "Cloud Computing",
      "Edge Devices",
    ]
  },

  clients: {
    name: "clients",
    title: "Some Clients",
    client_list: [
      {
        title: "Kibsi",
        text: "Real-time, generalized machine vision for video streams"
      },
      {
        title: "Cognitohealth",
        text: "Medical devices for patient safety"
      },
      {
        title: "MIT // Yale // Northeastern // Harvard Med",
        text: "Real-time fMRI software for biofeedback"
      },
      {
        title: "F0CAL",
        text: "Cloud-based system to access edge devices"
      },
      {
        title: "GestSure",
        text: "Real-time hand tracking in video"
      },
      {
        title: "SmartCut",
        text: "Autonomous lawnmower systems"
      },
      {
        title: "Sea Machines",
        text: "Autonomous watercraft systems"
      },
      {
        title: "NDensity",
        text: "Medical applications, visualization, web development"
      }
    ]
  },

  contact: {
    name: "contact",
    title: "Contact",
    link: "mailto:info@orchardscientific.org"
  }
}
