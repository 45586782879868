import { createContext, useState, useContext } from 'react';
import './App.css';
import { sections } from "./content.js";
import logo_1 from "./logo_1.png";
import logo_2 from "./logo_2.png";
import { slide as Menu } from 'react-burger-menu';
import { Grid, Row, Col } from 'react-flexbox-grid';

const Context = createContext();

const Provider = (props) => {
  const [state, setState] = useState(
    {
      isMenuOpen: false,
      visibleSection: "about",
    }
  );

  function maybeChangeMenuState(newMenuState) {
    if (state.isMenuOpen !== newMenuState) {
      setState({...state, isMenuOpen: newMenuState});
    }
  }

  return (
    <Context.Provider value={{
      isMenuOpen: state.isMenuOpen,
      menuStateChange: (newMenuState) => maybeChangeMenuState(newMenuState),
      visibleSection: state.visibleSection,
      setVisibleSection: (newSection) => setState({visibleSection: newSection, isMenuOpen: false})
    }}>
      {props.children}
    </Context.Provider>
  );
};


function OSMenu({changeSection}) {
  const ctx = useContext(Context);

  const styles = {
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.0)'
    }
  }

  return (
    <Menu width={200} styles={ styles } isOpen={ctx.isMenuOpen} onStateChange={(newState) => ctx.menuStateChange(newState.isOpen)}>
      <button className="menu-item link" onClick={() => ctx.setVisibleSection("about")}>About</button>
      <button className="menu-item link" onClick={() => ctx.setVisibleSection("expertise")}>Expertise</button>
      <button className="menu-item link" onClick={() => ctx.setVisibleSection("clients")}>Clients</button>
      <button className="menu-item link" onClick={() => window.open("mailto:info@orchardscientific.org")}>Contact</button>
    </Menu>
  );
}


function Header() {
  return (
    <header className="App-header">
      <Grid fluid>
        <Row center="xs">
          <Col xs={7} md={5}>
            <img src={logo_1} className="App-logo" alt="Orchard Scientific" />
          </Col>
          <Col xs={7} md={5}>
            <img src={logo_2} className="App-logo" alt="Orchard Scientific" />
          </Col>
        </Row>
      </Grid>
    </header>
  );
}


function Expertise() {
  return (
    <Grid fluid>
      <Row center="xs">
        {
          sections.expertise.list.map(area => {
            return (
              <Col xs={7} sm={7} md={5} lg={5}>
                <button className="area">
                  &#9900;{area}
                </button>
              </Col>
            );
          })
        }
      </Row>
    </Grid>
  );
}


function Clients() {
  return (
    <Grid fluid>
      <Row center="xs">
        {
          sections.clients.client_list.map(client => {
            return (
              <Col xs={10} sm={10} md={10} lg={10}>
                <button className="area">
                <p>&#10100;{client.title}&#10101; {client.text}</p>
                </button>
              </Col>
            );
          })
        }
      </Row>
    </Grid>
  );
}


function Body({visibleSection}) {
  const ctx = useContext(Context);

  return (
    <body className="App-body">
      <section id="{sections.about.name}" className={sections.about.name === ctx.visibleSection ? "none" : "hidden"}>
        <div className="App-section-header">
          <p>{sections.about.title}</p>
        </div>
        <div className="App-section-body">
          <p className="padded">{sections.about.text}</p>
        </div>
      </section>

      <section id="{sections.expertise.name}" className={sections.expertise.name === ctx.visibleSection ? "none" : "hidden"}>
        <div className="App-section-header App-section-header">
          <p>{sections.expertise.title}</p>
        </div>

        <div className="App-section-body">
          <Expertise/>
        </div>
      </section>

      <section id="{sections.clients.name}" className={sections.clients.name === ctx.visibleSection ? "none" : "hidden"}>
        <div className="App-section-header">
          <p>{sections.clients.title}</p>
        </div>
        <div className="App-section-body">
          <Clients/>
        </div>
      </section>
    </body>
  );
}

function Footer() {
  return (
    <footer className="App-footer">
      <section id="{sections.contact.name}">
        <button className="link onpage" onClick={() => window.open("mailto:info@orchardscientific.org")}>Contact</button>
      </section>
    </footer>
  );
}

function App() {
  return (
    <Provider>
      <OSMenu/>
      <Header/>
      <Body/>
      <Footer/>
    </Provider>
  );
}

export default App;
